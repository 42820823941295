<template>
  <div class="tg-wrapper">
    <div class="tg-block modal-popup">
      <div class="modal-popup__title">Уважаемый/ая {{ user.first_name }}!</div>
      <div class="body-popup__text text-normal">
       Приглашаем вас в наш Telegram - канал для педиатров и неонатологов
      </div>
      <div
        class="modal-popup__btn-accept d-md-inline-flex button button_pink"
        @click="goToTg()"
      >
        Перейти в Telegram
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "ToTgChanel",
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    goToTg() {
      if (this.user.invite_link) {
        window.open(this.user.invite_link, "_blank");
      }
      this.$router.push({ name: "MainPage" }).catch(() => {});
    },
  }
}
</script>

<style lang="scss" scoped>
.tg-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
}
.modal-popup {
  &__title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #830051;
    @media screen and (max-width: 767px) {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .body-popup {
    &__text {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 22px;
      &.text-bold {
        font-weight: 500;
      }
      &.text-normal {
        font-weight: 400;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>